// MarkdownParser.js
import React from 'react';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';

// If your text uses single asterisks for bold rather than italic,
// you could do a simple string replace from *...* => **...** before passing to ReactMarkdown.
// By default, Markdown interprets single asterisks as italic, double asterisks as bold.

const convertSingleStarToBold = (text) => {
  // Replace *some text* with **some text**
  // NOTE: This is a naive regex that may conflict with real italic usage.
  return text.replace(/\*(.*?)\*/g, '**$1**');
};

const MarkdownParser = ({ text }) => {
  // Convert single-asterisk to double-asterisk for bold
  const processedText = convertSingleStarToBold(text);

  return (
    <ReactMarkdown
      remarkPlugins={[remarkGfm]}  // optional plugin for GitHub-Flavored Markdown
      components={{
        // Force links to open in a new tab
        a: ({ node, ...props }) => (
          <a href={props.href} target="_blank" rel="noopener noreferrer">
            {props.children}
          </a>
        ),
      }}
    >
      {processedText}
    </ReactMarkdown>
  );
};

export default MarkdownParser;
