import React, { useEffect, useState } from 'react';
import QueueService from './/QueueService'; 
// Or ChatService, whichever you have
import MarkdownParser from './MarkdownParser';

const ChatList = ({ onChatClick, selectedPhone }) => {
  const [chats, setChats] = useState([]);
  const [error, setError] = useState('');

  useEffect(() => {
    const fetchChats = async () => {
      try {
        // Adjust the service function name to your actual endpoint
        const data = await QueueService.FetchRecentUsersWithMessages();
        setChats(data.messages); // 'messages' from the JSON
      } catch (err) {
        console.error('Error fetching chats:', err);
        setError('Failed to load chats');
      }
    };

    fetchChats();
  }, []);

  if (error) {
    return <p style={{ margin: '1rem' }}>Error: {error}</p>;
  }

  // When a chat is clicked, call the parent function
  const handleChatItemClick = (userId) => {
    onChatClick(userId);
  };

  const formatTimestamp = (timeString) => {
    if (!timeString) return '';
    const rawDate = new Date(timeString);
    // Example offset for IST (UTC+5:30); adjust as needed
    const withOffset = new Date(
      rawDate.getTime() + (5 * 60 + 30) * 60000
    );
    return withOffset.toLocaleTimeString([], {
      hour: '2-digit',
      minute: '2-digit',
      hour12: true,
    });
  };


  return (
    <div className="chat-list-container">
      <div className="chat-list-header">Chats</div>
      <ul className="chat-list">
        {chats.map((chat) => (
          <li
            key={chat.user_id}
            className={`chat-list-item ${selectedPhone === chat.user_id ? 'selected-chat' : ''}`}
            onClick={() => handleChatItemClick(chat.user_id)}
          >
            <p className="chat-user-id" style={{ fontWeight: 'bold', color: 'white' }}>{chat.name}</p>
            <div style={{ color: 'lightgrey' }}>
              <MarkdownParser text={chat.message_text.length > 50 ? `${chat.message_text.substring(0, 50)}...` : chat.message_text} />
            </div>
            <span style={{ color: 'lightgrey', fontSize: '0.8rem' }}>{formatTimestamp(chat.timestamp)}</span>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default ChatList;
