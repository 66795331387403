import React, { useState, useEffect, useRef } from 'react';
import QueueService from './QueueService'; // Or your relevant service
import MarkdownParser from './MarkdownParser';
const ChatWindow = ({ phone, token }) => {
  const [messages, setMessages] = useState([]);
  const [newMessage, setNewMessage] = useState('');
  const [loading, setLoading] = useState(false);
  const messagesEndRef = useRef(null);

  useEffect(() => {
    scrollToBottom();
  }, [messages]);

  const scrollToBottom = () => {
    if (messagesEndRef.current) {
      messagesEndRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  };

  // Fetch messages when phone changes
  useEffect(() => {
    const fetchMessages = async () => {
      if (!phone) return;
      try {
        setLoading(true);
        const data = await QueueService.FetchMessages(phone);
        setMessages(data || []);
      } catch (err) {
        console.error('Error fetching messages:', err);
      } finally {
        setLoading(false);
      }
    };
    fetchMessages();
  }, [phone]);

  const handleKeyPress = (e) => {
    if (e.key === 'Enter' && !e.shiftKey) {
      e.preventDefault();
      handleSendMessage();
    }
  };

  const handleSendMessage = async () => {
    if (!phone || !newMessage.trim()) return;

    try {
      const response = await QueueService.SendWhatsappMessage(
        `91${phone}`,
        newMessage,
        token
      );
      if (response && response.status === 'Success') {
        // Add the new message to local state
        const newMsg = {
          id: Date.now(), // Temporary ID
          message_text: newMessage,
          sent_by_system: true, 
          timestamp: new Date().toISOString(),
          entity_type: 'customer', // or whichever entity_type you use for your own messages
        };
        setMessages((prev) => [...prev, newMsg]);
        setNewMessage('');
      }
    } catch (err) {
      console.error('Error sending message:', err);
    }
  };

  // Helper to format timestamp
  const formatTimestamp = (timeString) => {
    if (!timeString) return '';
    const rawDate = new Date(timeString);
    // Example offset for IST (UTC+5:30); adjust as needed
    const withOffset = new Date(
      rawDate.getTime() + (5 * 60 + 30) * 60000
    );
    return withOffset.toLocaleTimeString([], {
      hour: '2-digit',
      minute: '2-digit',
      hour12: true,
    });
  };

  // For messages with entity_type = "business_customer_mapping",
  // just display the message text (no metadata handling).
  const renderBusinessCustomerMapping = (msg) => {
    return <MarkdownParser text={msg.message_text} />;
  };

  // For anything else (like entity_type = "customer"), just display the message text too.
  const renderOtherMessage = (msg) => {
    return <MarkdownParser text={msg.message_text} />;
  };

  if (!phone) {
    return (
      <div className="chat-window-container">
        <div className="chat-window-header">No Chat Selected</div>
        <div className="chat-window-messages">
          <div className="chat-window-message-placeholder">
            Please select a chat from the list.
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="chat-window-container">
      <div className="chat-window-header">Chat with {phone}</div>

      <div className="chat-messages">
        {loading && <div className="chat-window-loading">Loading messages...</div>}

        {!loading &&
          messages.map((msg, index) => {
            // Decide how to render based on entity_type
            let content;
            if (msg.entity_type === 'business_customer_mapping') {
              content = renderBusinessCustomerMapping(msg);
            } else {
              content = renderOtherMessage(msg);
            }

            return (
              <div
                key={msg.id || index}
                className={`message ${msg.sent_by_system ? 'system' : 'customer'}`}
              >
                <div className="message-content">
                  {content}
                  <span className="timestamp">
                    {formatTimestamp(msg.timestamp)}
                  </span>
                </div>
              </div>
            );
          })}

        <div ref={messagesEndRef} />
      </div>

      {/* Input for sending new message */}
      <div className="chat-window-input-container">
        <input
          type="text"
          placeholder="Type a message"
          className="chat-window-input"
          value={newMessage}
          onKeyPress={handleKeyPress}
          onChange={(e) => setNewMessage(e.target.value)}
        />
        <button className="chat-window-send-button" onClick={handleSendMessage}>
          Send
        </button>
      </div>
    </div>
  );
};

export default ChatWindow;
